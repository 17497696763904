<template>
  <div class="common-layout">
    <el-menu 
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      :ellipsis="false"
      background-color="rgb(231,228,58)"
      text-color="black"
      active-text-color="black"
       v-if="store.role==='employee' && store.viewMenu === true && store.journeyType ==false"
    >

      <div class="flex-grow" />
      <el-menu-item  index="1" @click="click"><RouterLink to="/" class="routerLink"><b>Home</b></RouterLink></el-menu-item>

      <el-menu-item index="2"><RouterLink to="/prenotazioni-info" class="routerLink"><b>Prenotazioni</b></RouterLink></el-menu-item>

      <el-menu-item index="3"><RouterLink to="/corse" class="routerLink"><b>Corse</b></RouterLink></el-menu-item>

      <el-menu-item index="4"><RouterLink to="/customer" class="routerLink"><b>Clienti</b></RouterLink></el-menu-item>

      <!-- <el-menu-item index="5"><RouterLink to="/contenuti" class="routerLink"><b>Contenuti</b></RouterLink></el-menu-item> -->

           <div class="flex-grow2" />
       <el-sub-menu index="2">
      <template #title>
      <el-icon><UserFilled /></el-icon>
      </template>
      <el-menu-item index="2-1"><RouterLink to="/profile" class="routerLink"><b>Profilo</b></RouterLink></el-menu-item>
      <!-- <el-menu-item index="2-1"><RouterLink to="/tariffe" class="routerLink"><b>Tariffe</b></RouterLink></el-menu-item> -->
      <el-menu-item index="2-1"><a @click="logout" class="routerLink"><b>Logout</b></a></el-menu-item>
      </el-sub-menu>
      
    </el-menu>

 </div>
 <div class="common-layout">
  <el-menu 
    :default-active="activeIndex"
    class="el-menu-demo"
    mode="horizontal"
    :ellipsis="false"
    background-color="rgb(231,228,58)"
    text-color="black"
    active-text-color="black"

     v-if="store.role==='adminEmployee' && store.viewMenu === true && store.journeyType ==false"
  >

  <div class="flex-grow" />
    <el-menu-item index="1"><RouterLink to="/tariffe" class="routerLink"><b>Home</b></RouterLink></el-menu-item>
    <!-- <el-menu-item index="5"><RouterLink to="/contenuti-vendor" class="routerLink"><b>Contenuti</b></RouterLink></el-menu-item> -->
    
    <div class="flex-grow2" />
     <el-sub-menu index="2">
    <template #title>
    <el-icon><UserFilled /></el-icon>
    </template>
    <el-menu-item index="2-1"><RouterLink to="/profile" class="routerLink"><b>Profilo</b></RouterLink></el-menu-item>
    <el-menu-item index="2-1"><a @click="logout" class="routerLink"><b>Logout</b></a></el-menu-item>
    </el-sub-menu>
  </el-menu>
  </div>
  
  <div class="common-layout">
    <el-menu 
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      :ellipsis="false"
      background-color="rgb(231,228,58)"
      text-color="black"
      active-text-color="black"
  
       v-if="store.role==='salesvendor' && store.viewMenu === true && store.journeyType ==false"
    >

    <div class="flex-grow" />
      <el-menu-item index="1"><RouterLink to="/customer" class="routerLink"><b>Home</b></RouterLink></el-menu-item>
      <!-- <el-menu-item index="5"><RouterLink to="/contenuti-vendor" class="routerLink"><b>Contenuti</b></RouterLink></el-menu-item> -->
      
      <div class="flex-grow2" />
       <el-sub-menu index="2">
      <template #title>
      <el-icon><UserFilled /></el-icon>
      </template>
      <el-menu-item index="2-1"><RouterLink to="/profile" class="routerLink"><b>Profilo</b></RouterLink></el-menu-item>
      <el-menu-item index="2-1"><a @click="logout" class="routerLink"><b>Logout</b></a></el-menu-item>
      </el-sub-menu>
    </el-menu>

    <div class="common-layout">
      <el-menu 
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        :ellipsis="false"
        background-color="rgb(231,228,58)"
        text-color="black"
        active-text-color="black"
    
         v-if="store.role==='employee' && store.viewMenu === true && store.journeyType ==true" 
      >
  
      <div class="flex-grow" />
        <el-menu-item index="1"><RouterLink to="/journey" class="routerLink"><b>Home</b></RouterLink></el-menu-item>
        
        <div class="flex-grow2" />
         <el-sub-menu index="2">
        <template #title>
        <el-icon><UserFilled /></el-icon>
        </template>
        <el-menu-item index="2-1"><RouterLink to="/profile" class="routerLink"><b>Profilo</b></RouterLink></el-menu-item>
        <el-menu-item index="2-1"><a @click="logout" class="routerLink"><b>Logout</b></a></el-menu-item>
        </el-sub-menu>
      </el-menu>
    
    <el-main>
      <router-view />
    </el-main>
  </div>


  
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { isLoggedStore } from './store/logged';
import { ref } from 'vue';
export default {
  name: 'App',
  components: {},
  setup() {
    const store = isLoggedStore();
    const router = useRouter();

    let activeIndex = ref('1');
    
    const logout = () => {
      store.viewMenu = false;
      store.isLogged = false;
      store.role= null;
      store.jwt=null;
      router.push('/login');
    };

    return {
      logout,
      store,
      activeIndex,
    };
  },
  methods: {
    click() {
      window.location.reload();
    }
  }
};
</script>

<style>
* {
  vertical-align: baseline;
  border: 0 none;
  outline: 0;
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #0B0D17;
  min-height: 100vh;
}

.routerLink {
  text-decoration: none !important;
}

.max-height {
  min-height: 100vh;
  height: 100%;
}

.flex-grow {
  flex-grow: 1;
}
.flex-grow2 {
  flex-grow: 0.9;
}
</style>
