import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

export const isLoggedStore = defineStore('logged', () => {
  const viewMenu = useStorage('viewMenu', false);
  const isLogged = useStorage('isLogged', false);
  const accessToken= useStorage('jwt','');
  const refreshToken=useStorage('refresh_token','');
  const id= useStorage('id','');
  const mail= useStorage('mail','');
  const role= useStorage('role','');
  const bookingType= useStorage('bookingType',false);
  const journeyType= useStorage('journeyType',false);


  const refreshExp= useStorage('refreshExp','');
  const jwtExp= useStorage('jwtExp','');
  const employee= useStorage('employee',{});
  

  return { viewMenu, isLogged,  accessToken,refreshToken,mail, role, id, refreshExp, jwtExp, employee,bookingType,journeyType};
});
