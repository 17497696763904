import API from './API';


export default {

    addJourney(journey){
        return API.post(`/journey` , journey);
      },  

    distanceJourney(data){
        return API.post(`/journey/distance` , data);
      },  
      
}