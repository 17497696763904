<template>
  <div
    style="
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-item: center;
    "
  >
    <div
      style="
        width: 400px;
        height: 275px;
        border: 2px solid #0c090a;
        border-radius: 20px;
        padding: 20px;
      "
    >
      <img
        style="width: 90px; height: 116px"
        :src="require('../assets/logo-simba-RIDIMENSIONATO.png')"
        class="image"
      />
      <el-form :model="loginData" label-width="100px">
        <el-form-item label="Nome utente">
          <el-input v-model="loginData.username" />
        </el-form-item>
        <el-form-item label="Password">
          <el-input
            v-model="loginData.password"
            type="password"
            placeholder="Inserire password"
            show-password
          />
        </el-form-item>

        <el-button color="rgb(231,228,58)" :dark="true" plain @click="logIn"
          >Log in!
        </el-button>
      </el-form>
      <router-link :to="{ path: `/reset-password` }" class="routerLink">
        <el-link style="margin-top: 11px" :underline="false"
          >Hai dimenticato la password? clicca quì!</el-link
        >
      </router-link>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { isLoggedStore } from '../store/logged';
import { reactive, toRaw } from 'vue';
import authAPI from '../services/authAPI.js';
import { ElMessage } from 'element-plus';
import employeeAPI from '../services/employeeAPI';
import partnerAPI from '@/services/partnerAPI';
//import storePrenotazione from '../store/prenotazione.js';

export default {
  name: 'LoginPage',
  components: {},
  setup() {
    const store = isLoggedStore();
    const router = useRouter();
    const loginData = reactive({
      username: '',
      password: '',
    });

    // decode the logged in user
    const parseJwt = (token) => {
      if (!token) {
        return;
      }
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');

      return JSON.parse(window.atob(base64));
    };

    const logIn_data = async (loginData) => {
      try {
        const response = await authAPI.login(loginData);
        return response;
      } catch (error) {
        console.log(error);
      }
    };

    const logIn = () => {
      logIn_data(toRaw(loginData)).then(async (response) => {
        try {
          if (response.status === 201) {
            store.accessToken = response.data.access_token;
            store.refreshToken = response.data.refreshtoken;

            const authRefresh = parseJwt(response.data.refreshtoken);
            store.refreshExp = authRefresh.exp * 1000;

            const auth = parseJwt(response.data.access_token);
            store.jwtExp = auth.exp * 1000;

            store.id = auth.sub;
            store.mail = auth.user.mail;
            store.role = auth.user.role;

            const employee = await employeeAPI.getEmployee(auth.sub);

            store.employee = toRaw(employee.data[0]);

            const responsePartner = await partnerAPI.getPartnerByEmployee(
              auth.sub
            );

            const partner = responsePartner.data[0].headquarters[0].partner;

            partner.booking_type==true ? store.bookingType=true: store.bookingType=false;
            partner.journey_type==true ? store.journeyType=true: store.journeyType=false;

            
            //storePrenotazione.employee= toRaw(employee.data[0]);

            if (auth.user.role == 'employee' && partner.booking_type==true && partner.journey_type==false) {
              store.isLogged = true;
              store.viewMenu = true;
              router.push('/');
            } else if (auth.user.role == 'adminEmployee' && partner.booking_type==true&& partner.journey_type==false ) {
              store.isLogged = true;
              store.viewMenu = true;
              router.push('/tariffe');
            } else if (auth.user.role == 'salesvendor' && partner.booking_type==true&& partner.journey_type==false) {
              store.isLogged = true;
              store.viewMenu = true;
              router.push('/customer');
            } else if(auth.user.role == 'employee' &&partner.booking_type==false&& partner.journey_type==true){

              store.isLogged = true;
              store.viewMenu = true;
              router.push('/journey');
            }else{
              ElMessage({
                message: 'Non sei autorizzato ',
                type: 'Error',
              });
              console.log('Non sei autorizzato');
            }
          } else if (response.status === 401) {
            ElMessage({
              message: 'Non sei autorizzato ',
              type: 'Error',
            });
            console.log('Non sei autorizzato');
          } else if (response.status === 404) {
            ElMessage({
              message: 'Username o password errati',
              type: 'Error',
            });
            console.log('errore inatteso');
          } else if (response.status === 400) {
            ElMessage({
              message: 'Error',
              type: 'Error',
            });
          }
        } catch (error) {
          ElMessage({
            message: 'Username o password errati',
            type: 'Error',
          });
          console.log('errore inatteso');
        }
      });
    };

    return {
      loginData,
      logIn,
      store,
    };
  },
};
</script>
