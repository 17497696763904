<template>
  <div>
    <h1 style="text-align: center">CREA UN NUOVO JOURNEY</h1>

    <div style="margin-top: 2%">
      <el-form :inline="true" class="journey-form">
        <el-form-item label="Seleziona una data">
          <el-date-picker
            v-model="journeyDate"
            type="date"
            :size="size"
            placeholder="Seleziona una data"
          />
        </el-form-item>
      </el-form>
    </div>
    <el-card class="journey-card" shadow="hover">
      <h3>Prima Tappa</h3>
      <div>
        <el-form :inline="true">
          <div class="route-row">
            <div class="route-item">
              <el-form-item label="Punto di Partenza">
                <el-select
                  v-model="routes[0].startPoint"
                  placeholder="Seleziona"
                >
                  <el-option
                    v-for="option in headquartersCascader"
                    :key="option.value"
                    :label="option.label"
                    :value="option.point"
                  />
                </el-select>
              </el-form-item>
            </div>
        
            <div class="route-item">
              <el-form-item label="Orario di Partenza">
                <el-time-picker
                  v-model="routes[0].dateStart"
                  placeholder="Seleziona orario"
                  arrow-control
                  format="HH:mm"
                  value-format="HH:mm"
                />
              </el-form-item>
            </div>
        
            <div class="route-item">
              <el-form-item label="Punto di Arrivo">
                <el-select
                  v-model="routes[0].endPoint"
                  placeholder="Seleziona"
                  @change="calculateArrivalTime(routes[0])"
                >
                  <el-option
                    v-for="option in headquartersCascader"
                    :key="option.value"
                    :label="option.label"
                    :value="option.point"
                  />
                </el-select>
              </el-form-item>
         
          </div>
        
          <div class="route-item">
        
              <el-form-item label="Orario di Arrivo">
                <el-time-picker
                  v-model="routes[0].dateEnd"
                  placeholder="Calcolato automaticamente"
                  arrow-control
                  format="HH:mm"
                  value-format="HH:mm"
                  disabled
                />
              </el-form-item>
            </div>
        
            <div class="action-buttons">
              <el-button
                type="success"
                @click="addRoute(0)"
                circle
              >
              <el-icon><CirclePlusFilled /></el-icon>
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
    </el-card>

    <el-card
      v-for="(route, index) in routes.slice(1)"
      :key="index"
      class="journey-card"
      shadow="hover"
    >
      <h3 style="margin-bottom: 10px">Tappa {{ index + 1 }}</h3>
      <el-form :inline="true">
        <div class="route-row">
          <div class="route-item">
            <el-form-item label="Punto di Partenza">
              <el-input
                :value="routes[index].endPoint?.address + ' ' + routes[index].endPoint?.street_number + ', ' + routes[index].endPoint?.city || ''"
                disabled
              />
            </el-form-item>
          </div>
        
          <div class="route-item">
            <el-form-item label="Orario di Partenza">
              <el-time-picker
                v-model="route.dateStart"
                placeholder="Seleziona orario"
                arrow-control
                format="HH:mm"
                value-format="HH:mm"
              />
            </el-form-item>
          </div>
        
          <div class="route-item">
            <el-form-item label="Punto di Arrivo">
              <el-select
                v-model="route.endPoint"
                placeholder="Seleziona"
                @change="calculateArrivalTime(route)"
              >
                <el-option
                  v-for="option in headquartersCascader"
                  :key="option.value"
                  :label="option.label"
                  :value="option.point"
                />
              </el-select>
            </el-form-item>
          </div>
        
          <div class="route-item">
            <el-form-item label="Orario di Arrivo">
              <el-time-picker
                v-model="route.dateEnd"
                placeholder="Calcolato automaticamente"
                arrow-control
                disabled
                format="HH:mm"
                value-format="HH:mm"
              />
            </el-form-item>
          </div>
        
          <div class="action-buttons">
            <el-button
              type="success"
              @click="addRoute(index)"
              circle
            >
            <el-icon><CirclePlusFilled /></el-icon>
          </el-button>
            <el-button
              type="danger"
              @click="removeRoute(index + 1)"
              circle
            >
            <el-icon><RemoveFilled /></el-icon>
            </el-button>
          </div>
        </div>
        
        
      </el-form>
    </el-card>

    <el-card class="journey-card" shadow="hover">
      <h3 style="margin-bottom: 10px">Ultima Tappa</h3>
      <el-form :inline="true" class="route-form">
        <el-form-item label="Punto di Partenza">
          <el-input
            :value="
              routes[routes.length - 1].endPoint?.address +
                ' ' +
                routes[routes.length - 1].endPoint?.street_number +
                ', ' +
                routes[routes.length - 1].endPoint?.city || ''
            "
            disabled
          />
        </el-form-item>

        <el-form-item label="Orario di Partenza">
          <el-time-picker
            v-model="routes[routes.length - 1].dateEnd"
            placeholder="Calcolato automaticamente"
            arrow-control
            format="HH:mm"
            value-format="HH:mm"
          />
        </el-form-item>

        <el-form-item label="Punto di Arrivo">
          <el-input
            :value="
              routes[0].startPoint?.address +
                ' ' +
                routes[0].startPoint?.street_number +
                ', ' +
                routes[0].startPoint?.city || ''
            "
            disabled
          />
        </el-form-item>

        <el-form-item label="Orario di Arrivo">
          <el-time-picker
            v-model="routes[routes.length - 1].dateEnd"
            placeholder="Calcolato automaticamente"
            arrow-control
            disabled
            format="HH:mm"
            value-format="HH:mm"
          />
        </el-form-item>
      </el-form>
    </el-card>

    <div style="text-align: center; margin-top: 20px">
      <el-button
        type="success"
        size="large"
        @click="saveJourney"
      >
        Salva Journey
      </el-button>
    </div>
  </div>
</template>

<script>
import { ref /* ,watch  */ } from 'vue';
import bookingAPI from '../services/bookingAPI';
import journeyAPI from '../services/journeyAPI';
import { isLoggedStore } from '../store/logged';
import {
  Delete,
} from '@element-plus/icons-vue'
export default {
  components: {},
  name: 'JourneyPage',
  setup() {
    const storeLogin = isLoggedStore();
    const headquartersCascader = ref([]);
    const headquarters = ref([]);

    const journeyDate = ref(null);
    const routes = ref([
      {
        startPoint: {
          address: '',
          street_number: '',
          cap: '',
          city: '',
          province: '',
          latitude: '',
          longitude: '',
        },
        endPoint: {
          address: '',
          street_number: '',
          cap: '',
          city: '',
          province: '',
          latitude: '',
          longitude: '',
        },
        dateStart: '',
        dateEnd: '',
      },
    ]);

    const loadHeadquarters = async () => {
      try {
        const response = await bookingAPI.getHeadquartersByEmployee(
          storeLogin.id
        );
        response.data[0].headquarters.forEach((headquarter) => {
          headquartersCascader.value.push({
            value: headquarter.id,
            label: `${headquarter.address} ${headquarter.street_number} ${headquarter.city}`,
            point: {
              address: headquarter.address,
              street_number: headquarter.street_number,
              cap: headquarter.cap,
              city: headquarter.city,
              province: headquarter.province,
              latitude: headquarter.latitude,
              longitude: headquarter.longitude,
            },
          });
          headquarters.value.push(headquarter);
        });
      } catch (error) {
        console.error(
          'Errore nel caricamento degli headquarters:',
          error.message
        );
      }
    };

    const addRoute = (index) => {
      const newRoute = {
        startPoint: routes.value[index].endPoint,
        endPoint: null,
        dateStart: '',
        dateEnd: '',
      };
      routes.value.splice(index + 1, 0, newRoute);
      if (routes.value[index + 2]) {
        routes.value[index + 2].startPoint = newRoute.endPoint;
      }
    };

    const removeRoute = (index) => {
      routes.value.splice(index, 1);
    };

    const saveJourney = async () => {
      try {
        const journeyPayload = {
          date: journeyDate.value,
          driverId: null,

          routes: routes.value.map((route) => {
            const [startHour, startMinute] = route.dateStart
              .split(':')
              .map(Number);
            const [endHour, endMinute] = route.dateEnd.split(':').map(Number);

            const fullDateStart = new Date(journeyDate.value);
            fullDateStart.setHours(startHour, startMinute, 0);

            const fullDateEnd = new Date(journeyDate.value);
            fullDateEnd.setHours(endHour, endMinute, 0);

            return {
              startPoint: route.startPoint,
              endPoint: route.endPoint,
              dateStart: fullDateStart.toISOString(),
              dateEnd: fullDateEnd.toISOString(),
            };
          }),
        };

        await journeyAPI.addJourney(journeyPayload);
        alert('Journey salvato con successo!');
      } catch (error) {
        console.error('Errore nel salvataggio del Journey:', error.message);
      }
    };

    const calcDistance = async (origin, destination) => {
      let response = await journeyAPI.distanceJourney({
        startPoint: origin,
        endPoint: destination,
      });

      return response.data.min;
    };

    const calculateArrivalTime = async (route) => {
      if (route.startPoint && route.endPoint && route.dateStart) {
        const min = await calcDistance(route.startPoint, route.endPoint);

        const startTime = new Date(`1970-01-01T${route.dateStart}:00`);
        startTime.setMinutes(startTime.getMinutes() + min);

        route.dateEnd = startTime.toTimeString().slice(0, 5);
      }
    };

    loadHeadquarters();
    return {
      headquartersCascader,
      journeyDate,
      routes,
      addRoute,
      removeRoute,
      saveJourney,
      calcDistance,
      calculateArrivalTime,
      Delete
    };
  },
};
</script>

<style>
.journey-section {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.route-row {
  display: flex;
  align-items: center;
  gap: 20px; 
}

.route-item {
  flex: 1; /* Ensures all items have equal width */
}

.action-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 33px;
}
</style>
